







































































































































































import { Component, Vue } from "vue-property-decorator";
import Project from "@/components/Project.vue";
import Portfolio from "@/components/Portfolio.vue";
import Navigation from "@/components/Navigation.vue";

@Component({
  components: { Navigation, Portfolio, Project },
})
export default class Enterprise extends Vue {
  // if desktop or not
  isDesktop() {
    return this.$vuetify.breakpoint.lgAndUp;
  }

  // open link
  open() {
    //window.open(this.link);
  }
}
